import React, { useState } from 'react'
import s from './login.module.css'

type Props = {
    saveChange: (password: string) => void
    close: () => void
   
}

const Login = ({ saveChange, close }: Props) => {

    const [password, setPassword] = useState('')
    return (
        <div className={s.login}>
            <h3>Сохранить изменения?</h3>
            <input type="password" placeholder='Введите пароль' value={password}
                onChange={(e) => { setPassword(e.currentTarget.value) }} />
            <div className={s.buttons}>
                <button onClick={() => {
                    saveChange(password);
                    close();
                }}>Сохранить</button>
                <button onClick={close}>Отмена</button>
            </div>
        </div>
    )
}

export default Login