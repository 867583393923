import React from 'react'
import s from './navBar.module.css'
import { useNavigate } from 'react-router-dom'
import pic2 from '../img/Bar_word.png'
import pic3 from '../img/spichki_WE.png'


type Props = {}

const NavBar = (props: Props) => {
  const navigator = useNavigate()

  return (
    <div className={s.navBar}>
      <div className={s.text_S}>
        <div >
          <img src={pic3} alt="" />
        </div>
      </div>
      <div onClick={() => { navigator('/okt19') }} className={s.text_O}>
        <p>Октябрьская, 19Б</p>
        <div className={s.textImg_O}>
          <img src={pic2} alt="" />
        </div>


      </div>
      <div onClick={() => { navigator('/koms5') }} className={s.text_O}>
        <p>Комсомольская, 5A</p>
        <div className={s.textImg_O}>
          <img src={pic2} alt="" />
        </div>

      </div>
      <div onClick={() => { navigator('/luch') }} className={s.text_O}>
        <p>Независимости, 95</p>
        <div className={s.textImg_O}>
          <img src={pic2} alt="" />
        </div>

      </div>
     
      <div onClick={() => { window.open('https://www.instagram.com/spichki_mogilev?igsh=bnZmbnQ4MnRjODc=') }} className={s.text_O}>
        <p>Могилев</p>
        <div className={s.textImg_O}>
          <img src={pic2} alt="" />
        </div>
      </div>
      <div onClick={() => { window.open('https://www.instagram.com/spichki_kobryn?igsh=b2E0d2ZwYXE0dnpp') }} className={s.text_O}>
        <p>Кобрин</p>
        <div className={s.textImg_O}>
          <img src={pic2} alt="" />
        </div>
      </div>
      <div onClick={() => { window.open('https://www.instagram.com/spichki_brest?igsh=djJ3OXJnODJqcHUy') }} className={s.text_O}>
        <p>Брест</p>
        <div className={s.textImg_O}>
          <img src={pic2} alt="" />
        </div>
      </div>
      <div onClick={() => { window.open('https://spichki-fr.ru/') }} className={s.text_O}>
        <p>Франшиза Spichki</p>
        <div className={s.textImg_O}>
          <img src={pic2} alt="" />
        </div>
      </div>
    </div>
  )
}
export default NavBar