import React, { useEffect, useState } from 'react'
import s from './menuEdit.module.css'
import { IDrink, IFood, IMenu } from '../../app/interfaces'
import menuKoms from '../../menu_drink_koms.json'
import { FaMinus, FaPlus } from "react-icons/fa6";
import { motion } from 'framer-motion'
import { useAppDispatch, useAppSelector } from '../../app/Hooks'
import { showSeason } from '../../app/slice'
import Drink from './Drink'
import Food from './Food'
import { useChangeMenuMutation, useGetMenuQuery } from '../../app/slice_API'
import Login from './Login';

type Props = {
  anim4: string
}

const MenuEdit = ({ anim4 }: Props) => {

  const dispatch = useAppDispatch()
  const [plusDrink, setPlusDrink] = useState<boolean[]>([true])
  const [plusFood, setPlusFood] = useState<boolean[]>([true])
  const [plusSeason, setPlusSeason] = useState<boolean[]>([true])
  const [menu, setMenu] = useState<IMenu>(menuKoms)
  const [inputMenu, setInputMenu] = useState('koms5')
  const seasonShow = useAppSelector(state => state.spichki.season)
  const currMenu = useGetMenuQuery(inputMenu)
  const [showLog, setShowLog] = useState(false)
  const [changeMenu] = useChangeMenuMutation()
  const [menuSeason, setMenuSeason] = useState<IFood[]>([{
    name: '',
    list:
      [{
        name: '',
        gramm: '',
        discr: '',
        cost: ''
      }]
  }])



  useEffect(() => {
    window.scrollTo(0, 0)
    setPlusDrink(menu.drinks.map(el => true))
    setPlusFood(menu.food.map(el => true))
    setPlusSeason(menuSeason.map(el => true))

  }, [menu, menuSeason])

  useEffect(() => {
    if (currMenu.data?.menu) {
      setMenu(currMenu.data.menu)
      dispatch(showSeason(currMenu.data.season))
    }
    if (currMenu.data?.seasonMenu) setMenuSeason(currMenu.data.seasonMenu)

  }, [inputMenu, currMenu.data, dispatch])

  const onClick = (index: number, menuShow: string) => {
    if (menuShow === 'drink') {
      let a = plusDrink.map((el, i) => {
        if (i === index) {
          return !el
        }
        return true
      })

      setPlusDrink([...a])
      setPlusFood(menu.food.map(el => true))
      setPlusSeason(menuSeason.map(el => true))
    }
    if (menuShow === 'food') {
      let a = plusFood.map((el, i) => {
        if (i === index) {
          return !el
        }
        return true
      })

      setPlusFood([...a])
      setPlusDrink(menu.drinks.map(el => true))
      setPlusSeason(menuSeason.map(el => true))

    }
    if (menuShow === 'season') {
      let a = plusSeason.map((el, i) => {
        if (i === index) {
          return !el
        }
        return true
      })

      setPlusSeason([...a])
      setPlusDrink(menu.drinks.map(el => true))
      setPlusFood(menu.food.map(el => true))
    }
  }
  const saveChange =  (pw: string) => {
     


     
 changeMenu({menu, menuSeason, pw, 
      season:seasonShow, barName: inputMenu})
      .unwrap().then(payload => alert(payload.data)
      ).catch(error=> alert(error.data.message)
      )
    
  }
  

  const drinks = menu.drinks.map((el, i) => {

    return <div key={el.nameD + i}>

      <div className={plusDrink[i] ? s.wrapper : s.wrapperPlus}>
        <div className={s.hidden}>
          <motion.div whileHover={{ backgroundColor: '#0e0e0e', color: '#fff' }} className={s.wrapperName}>
            <h3>{el.nameD}</h3> <h3>{el.ml}</h3>
          </motion.div>

          {(plusDrink[i] && el.list[0].length > 0) ? <FaPlus onClick={() => { onClick(i, 'drink') }} size={(anim4 === 'navig') ? 30 : 20} className={s.plus} /> :
            <FaMinus onClick={() => { onClick(i, 'drink') }} size={(anim4 === 'navig') ? 30 : 20} className={s.plus} />}
        </div>
        {(!plusDrink[i] && el.list[0].length > 0) && <div className={s.plusA}>{<Drink index={i} menu={menu.drinks}
          changeMenu={(newDrink: IDrink[]) => { setMenu({ drinks: [...newDrink], food: menu.food }) }} />}</div>}
      </div>

      <hr />
    </div>
  })
  const food = menu.food.map((el, i) => {
    return <div key={el.name + i}>
      <div className={plusDrink[i] ? s.wrapper : s.wrapperPlus}>
        <div className={s.hidden}>
          <motion.div whileHover={{ backgroundColor: '#0e0e0e', color: '#fff' }} className={s.wrapperName}>
            <h3>{el.name}</h3>
            {(el.list[0].name === "") && <h3>{el.list[0].cost}</h3>}
          </motion.div>

          {(plusFood[i] && el.list[0].name.length > 0) ? <FaPlus onClick={() => { onClick(i, 'food') }} size={(anim4 === 'navig') ? 30 : 20} className={s.plus} /> :
            <FaMinus onClick={() => { onClick(i, 'food') }} size={(anim4 === 'navig') ? 30 : 20} className={s.plus} />}
        </div>
        {(!plusFood[i] && el.list[0].name.length > 0) && <div className={s.plusA}>{<Food index={i}
          changeMenu={(newFood: IFood[]) => { setMenu({ food: [...newFood], drinks: menu.drinks }) }} menu={menu.food} />}</div>}
      </div>

      <hr />
    </div>
  })

  const season = menuSeason.map((el, i) => {
    return (
      <div key={el.name + i}>
        <div className={plusDrink[i] ? s.wrapper : s.wrapperPlus}>
          <div className={s.hidden}>
            <motion.div whileHover={{ backgroundColor: '#0e0e0e', color: '#fff' }} className={s.wrapperName}>
              <h3>{el.name}</h3>
              {(el.list[0].name === "") && <h3>{el.list[0].cost}</h3>}
            </motion.div>

            {(plusSeason[i] && el.list[0].name.length > 0) ? <FaPlus onClick={() => { onClick(i, 'season') }} size={(anim4 === 'navig') ? 30 : 20} className={s.plus} /> :
              <FaMinus onClick={() => { onClick(i, 'season') }} size={(anim4 === 'navig') ? 30 : 20} className={s.plus} />}
          </div>
          {(!plusSeason[i] && el.list[0].name.length > 0) && <div className={s.plusA}>{<Food index={i}
            changeMenu={(newFood: IFood[]) => { setMenuSeason(newFood) }} menu={menuSeason} />}</div>}
        </div>

        <hr />
      </div>
    )
  })
  return (
    <div className={!currMenu.isLoading ? s.menu : s.menuOpacity}>


      <div className={s.container}>
        {showLog ? <Login 
          close={() => setShowLog(false)}
          saveChange={saveChange} /> : <button onClick={() => { setShowLog(true) }}
            className={s.saveCh}>Сохранить изменения</button>}
        <h1>МЕНЮ</h1>

        <select name="menu" id="menu" value={inputMenu} onChange={(e) => {
          setInputMenu(e.currentTarget.value)
        }}>
          <option value="koms5">Комсомольская</option>
          <option value="okt19">Октябрьская</option>
          <option value="luch">Луч</option>
        </select>

        <>
          <div className={s.checkSeason}>

            <h2 style={{ color: 'red' }} >Сезонное предложение</h2>
            <input type="checkbox" checked={seasonShow} onChange={() => { dispatch(showSeason(!seasonShow)) }} />
          </div>


          <div className={s.drinks}>
            {seasonShow && season}
          </div>
        </>

        <h2>Напитки</h2>

        <div className={s.drinks}>
          {drinks}
        </div>

        <h2>ЗАКУСКИ</h2>
        <div className={s.drinks}>
          {food}
        </div>

      </div>

    </div>
  )
}
export default MenuEdit